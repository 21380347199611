<template>
  <Loader v-if="loading" />
  <div class="card">
    <div class="flex justify-content-between">
      <div class="col-2">
        <h4>Convenzioni Agf</h4>
      </div>
      <div class="col-10 flex justify-content-end">
        <Button icon="pi pi-plus" v-tooltip.left="`Aggiungi convenzione`" @click="dialog_create_convenzione = true"/>
      </div>
    </div>
    <div v-for="(group_convenzione, agente) in convenzioni" :key="agente" class="card flex justify-content-between align-items-center">
      <div class="col-2">{{ agente }}</div>
      <div class="col-10 flex flex-column">
        <div v-for="(convenzione, nome_convenzione) in group_convenzione" :key="nome_convenzione">
          <DataTable :value="convenzione" tableStyle="width: 100%">
            <template #header>
              {{ nome_convenzione }}
            </template>
            <Column header="Data creazione">
              <template #body="{data}">
                {{ moment(data.data_creazione).format('DD/M/Y') }}
              </template>
            </Column>
            <Column header="Url">
              <template #body="{data}">
                {{ services.ask_landing.url+'/'+data.token }}
              </template>
            </Column>
            <Column field="name_offerta" header="Offerta"></Column>
            <Column header="Opzioni">
              <template #body="{data}">
                {{ getInfoOfferta(data) }}
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="dialog_create_convenzione" modal header="Crea convenzione" :style="{ width: '50%' }">
    <div class="flex flex-wrap justify-content-center">
      <div class="col-12 md:col-6">
        <label class="mb-2">Nome convenzione</label>
        <InputText v-model="new_convenzione.nome_convenzione" class="w-full"/>
        <small v-show="validationErrors.nome_convenzione" class="p-error">Campo necessario</small>
      </div>
      <div class="col-12 md:col-6">
        <label class="mb-2">Seleziona agente</label>
        <Dropdown v-model="new_convenzione.agente" :options="agenti_offerte" filter optionLabel="name" class="w-full" @change="onSelectionAgente($event)"/>
        <small v-show="validationErrors.agente" class="p-error">Campo necessario</small>
      </div>
      <div v-if="new_convenzione.agente" class="w-full flex">
        <div v-for="type_offerte in types_offerte" :key="type_offerte.value" class="col-6">
          <div class="flex col-12 align-items-center">
            <Checkbox v-model="type_offerte.selected" :binary="true"/>
            <label class="m-2">Offerta {{ type_offerte.name }}</label>
          </div>
          <div v-if="type_offerte.selected">
            <div class="col-12" v-for="type_offerta in type_offerte.offerte" :key="type_offerta.type">
              <label class="mb-2">Seleziona offerta {{type_offerta.label}}</label>
              <Dropdown v-model="new_convenzione.offerte[type_offerte.label][type_offerta.type]" :options="type_offerta.offerte" filter optionLabel="name" class="w-full"/>
              <div class="mt-3 flex justify-content-around" v-if="new_convenzione.offerte[type_offerte.label][type_offerta.type]">
                {{ getInfoOfferta(new_convenzione.offerte[type_offerte.label][type_offerta.type], 'tipologia', 1) }}
              </div>
              <small v-show="validationErrors[`offerta_${type_offerta.type}`]" class="p-error">Campo necessario</small>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 flex justify-content-center">
        <div class="button-wrapper mb-2">
          <span class="p-1 label flex align-items-center justify-content-center">Carica logo</span>
          <input class="upload-file" name="file[]" type="file" ref="file" @change="handleFile($event)" accept="image/jpeg,image/jpg,image/png"/>
        </div>
      </div>
      <div v-if="new_convenzione.file" class="m-1">File selezionato: {{ new_convenzione.file.name }}</div>
      <small v-show="validationErrors.file" class="p-error">Campo necessario</small>
      <div class="col-12 flex justify-content-center m-2">
        <Button label="Salva" icon="pi pi-check" @click="sendNewConvenzione" :disabled="isDisabledSendNewConvenzione"/>
      </div>
    </div>
  </Dialog>
</template>

<script>
// @ is an alias to /src
// eslint-disable-next-line no-unused-vars
import {ref, onMounted, getCurrentInstance, computed } from 'vue';
import Loader from "@/components/Loader.vue"
import moment from 'moment'
import { useStore } from 'vuex'
import Service from '@/services/ask-pratiche/pratiche-wizard'
import { useToast } from 'primevue/usetoast';
import roles from '@/config/roles'
import { useRoute, useRouter } from 'vue-router'
import services from '@/config/services'

export default {
  components: {
    Loader
  },  
  setup (props, {emit}) {
    const toast = useToast();
    const loading = ref(false)
    const self = getCurrentInstance();
    self.$eventBus = self.appContext.config.globalProperties.$eventBus
    const service = new Service(self.$eventBus)
    const convenzioni = ref({})
    const validationErrors = ref({})
    const getData = async () => {
      loading.value = true  
      await service.getConvenzioni()
      .then((res)=>{
        convenzioni.value = res.items
      })
      .finally(()=> {
        loading.value = false
      })
    }
    
    const agenti_offerte = ref([])
    const getAgentiOfferte = async () => {
      await service.getAgentiOfferte()
      .then((res)=>{
        agenti_offerte.value = res.items
      })
    }        
    const selectedAgente = ref()
    const offerte_elettrico = ref([])
    const offerte_gas = ref([])
    const onSelectionAgente = (evt) => {
      types_offerte.value.forEach(type_offerte => {
        type_offerte.selected = false
        type_offerte.offerte.forEach(type_offerta => {
          type_offerta.offerte = evt.value.offerte.filter(offerta => offerta.tipologia == type_offerta.id && offerta.cod_type_offerta == type_offerte.value)
        })
      })
    }

    const new_convenzione = ref({
      nome_convenzione: '',
      agente: null,
      offerte: {
        fix: {
          ee: null,
          gas: null,
        },
        index: {
          ee: null,
          gas: null
        }
      },
      file: null
    })
    const prop_to_send = {
      nome_convenzione: {prop: [], json: true},
      agente: {prop: ['id'], json: true},
      offerte: {prop: [], json: true},
      // offerta_ee: {prop: ['id', 'f1', 'pcv', 'cod_type_offerta'], json: true},
      // offerta_gas: {prop: ['id', 'm', 'pcv', 'cod_type_offerta'], json: true},
      file: {prop: [], json: false},
    }
    const dialog_create_convenzione = ref(false)
    const getInfoOfferta = (offerta, prop = 'type', type_prop = 'ee') => {            
      let type = offerta[prop]
      let type_unit = type == type_prop ? 'PUN' : 'PSV'
      let unit = type == type_prop ? 'Kwh' : 'Smc'
      let dato = type == type_prop ? offerta.f1.toFixed(2).replace('.', ',') : offerta.m.toFixed(2).replace('.', ',')
      let pcv = offerta.pcv
      return `PCV ${pcv} €/Mese - ${type_unit} ${dato} €/${unit}`
    }
    const types_offerte = ref([
      {value: 1,label: 'fix', name: 'Fissa', selected: false, offerte: [
        {id: 1, label: 'elettrico', type: 'ee', offerte:[]},
        {id: 2, label: 'gas', type: 'gas', offerte:[]}
      ]},
      {value: 2,label: 'index', name: 'Variabile', selected: false, offerte: [
        {id: 1, label: 'elettrico', type: 'ee', offerte:[]},
        {id: 2, label: 'gas', type: 'gas', offerte:[]}
      ]}
    ])

    onMounted(() => { 
      getAgentiOfferte()      
      getData()
    })
    const handleFile = (evt) => {
      new_convenzione.value.file = self.refs.file.files[0]
    };
    const sendNewConvenzione = async () => {
      
      // validationErrors.value = {}
      loading.value = true
      let fd = new FormData()
      Object.keys(new_convenzione.value).forEach(prop => {
        let data_prop = prop_to_send[prop]
        let name = prop == 'file' ? `${prop}[]` : prop
        let data
        
        if (data_prop.prop.length) {
          data = {}
          data_prop.prop.forEach(el => {              
            data[el] = new_convenzione.value[prop][el]
          })
        }else{
          data = new_convenzione.value[prop]
        }
                  
        fd.append(name, data_prop.json ? JSON.stringify(data) : data)
      })
      await service.sendNewConvenzione(fd)
      .then((res) => {
        let severity = res.item.success ? 'success' : 'warn'
        let detail = res.item.msg
        toast.add({severity: severity, detail: detail, life: 5000})
        resetNewConvenzione()
      })
      .finally(()=>{
        getData()
        loading.value = false
      })
      // if (!validateForm()) {
      // }
    }
    // const validateForm = () => {
    //   Object.keys(new_convenzione.value).forEach(prop => {
    //     if (!new_convenzione.value[prop]) {
    //       validationErrors.value[prop] = true
    //     }else{
    //       delete validationErrors.value[prop]
    //     }
    //   })      
    //   return Object.keys(validationErrors.value).length > 0
    // }
    const resetNewConvenzione = () => {
      new_convenzione.value = {
        nome_convenzione: '',
        agente: null,
        offerte: {
          fix: {
            ee: null,
            gas: null,
          },
          index: {
            ee: null,
            gas: null
          }
        },
        file: null
      }
      dialog_create_convenzione.value = false
    }

    const isDisabledSendNewConvenzione = computed(() => {
      let {nome_convenzione, agente, offerte, file} = new_convenzione.value

      let array_check = [
        nome_convenzione == '',
        agente == null,
        file == null
      ]
      if (!types_offerte.value[0].selected && !types_offerte.value[1].selected) {
        array_check.push(true)
      }
      if (types_offerte.value[0].selected) {
        array_check.push(offerte.fix.ee == null)
        array_check.push(offerte.fix.gas == null)
      }

      if (types_offerte.value[1].selected) {
        array_check.push(offerte.index.ee == null)
        array_check.push(offerte.index.gas == null)
      }      
      return array_check.some(elemento => elemento === true)
    })

    return {
      loading,
      convenzioni,
      validationErrors,
      services,
      moment,
      getInfoOfferta,
      dialog_create_convenzione,
      agenti_offerte,
      selectedAgente,
      onSelectionAgente,
      offerte_elettrico,
      offerte_gas,
      new_convenzione,
      // types_tipologia,
      handleFile,
      resetNewConvenzione,
      sendNewConvenzione,
      types_offerte,
      isDisabledSendNewConvenzione,
    }
  },
}
</script>

<style>

</style>
<style scoped>

</style>
